<template>
  <!-- 新增白名单 -->
  <div class="addList_box">
    <app-container :title="'新增白名单'" :background="tool.getThemeList(theme)">
      <div class="content_box">
        <div
          class="add_list"
          v-for="(item, index) in batchObj.list"
          :key="index"
        >
          <div class="card_name">
            <span class="desc_num">{{ index + 1 }}</span>
            <van-field
              v-model.trim="item.whiteNumberName"
              input-align="left"
              label="昵称:"
              type="text"
              placeholder="请输入昵称 例如:爸爸"
              maxlength="20"
              :right-icon="index ? 'cross' : ''"
              @click-right-icon="clickIcon"
            >
            </van-field>
          </div>
          <div class="underline"></div>
          <div class="card_num">
            <van-field
              v-model.trim="item.whiteNumber"
              input-align="left"
              label="电话:"
              type="number"
              placeholder="请输入需要拨打的电话号码"
              maxlength="15"
              :error-message="item.whiteNumber ? '' : '请输入电话'"
              required
            >
              <template #right-icon>
                <span class="inputNum"
                  >已填:{{ item.whiteNumber.length }}位</span
                >
              </template>
            </van-field>
          </div>
          <div class="underline"></div>
        </div>
        <van-button
          block
          type="primary"
          @click="continueAdd({ whiteNumber: '', whiteNumberName: '' })"
          class="add_button"
          icon="plus"
        >
          继续添加
        </van-button>
        <van-button
          block
          type="primary"
          :disabled="judgeMobile"
          @click="submit"
          class="submit"
          icon="success"
        >
          提交保存
        </van-button>
        <div class="explain">
          <div class="explain_desc">温馨提示:</div>
          <div class="explain_text">
            1、只有添加了要拨打的亲人号码，才可以正常通话
          </div>
          <div class="explain_text">
            2、重要提示：添加号码后，请将手表关机一下，然后开机等30分钟后，手表自动解锁
          </div>
          <div class="explain_text">
            3、如果一次性添加号码太多、打电话提示【此号码不允许呼叫的话】还请耐心等待60分钟后，白名单显示已添加，才可以正常通话。
          </div>
        </div>
      </div>
    </app-container>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { synWhiteList, saveWhiteList } from "_API_/api.services";
export default {
  setup() {
    const state = reactive({
      theme: "", //主题名称
      card: "", //卡号
      batchObj: {
        cardId: "",
        list: [
          {
            whiteNumber: "",
            whiteNumberName: ""
          }
        ]
      }, //新增白名单对象
      deepList: [], //初始化白名单对象的list
      length: "", //路由所传递过来的卡号已添加的白名单数量
      maxWhitelist: "" //卡号所能添加的最大白名单数量
    });
    const { proxy: ctx } = getCurrentInstance();
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      //继续添加模板
      continueAdd(obj) {
        if (
          state.batchObj.list.length + Number(state.length) >
          state.maxWhitelist - 1
        ) {
          tool.toast(
            {
              msg: `最多只能添加${state.maxWhitelist}个白名单`,
              duration: 1500
            },
            () => {}
          );
          return;
        }
        //添加新增的模板到list
        state.batchObj.list.push(obj);
      },
      //删除模板
      clickIcon(index) {
        state.batchObj.list.splice(index, 1);
      },
      //提交保存
      submit() {
        //昵称与电话均有填写的list
        let _list = state.batchObj.list;
        if (
          _list.length == 1 &&
          _list[0].whiteNumber == "" &&
          _list[0].whiteNumberName == ""
        ) {
          tool.toast(
            {
              msg: "请输入电话号码",
              duration: 1500
            },
            () => {}
          );
          return;
        }
        //提交保存新增白名单
        saveWhiteList(state.batchObj).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast(
              {
                msg: res.msg,
                duration: 1500
              },
              () => {
                //重置白名单填写的list
                Object.assign(state.batchObj, { list: state.deepList });
                //返回上一个页面
                window.history.back();
              }
            );
            return;
          }
          if (res.code === 10011) {
            tool.confirm(
              {
                title: "提示",
                msg: res.msg,
                confirmButtonText: "去充值"
              },
              _ => {
                router.push({
                  name: "personal-packageRecharge",
                  query: {
                    active: 0
                  }
                });
              }
            );
            return;
          }
          tool.toast(
            {
              msg: res.msg,
              duration: 1500
            },
            () => {}
          );
        });
      },
      //同步白名单
      asyncList() {
        synWhiteList({ cards: state.card }).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast({
              msg: `白名单同步成功`,
              duration: 1500
            });
            return;
          }
          tool.toast(
            {
              msg: res.msg,
              duration: 1500
            },
            () => {}
          );
        });
      }
    };
    //计算属性=>judgeMobile
    const judgeMobile = computed(() => {
      // let arr = state.batchObj.list.filter(item => {
      //   let reg = /^([0-9]{3,4})[0-9]{7,8}$|^1[0-9]{10}$/;
      //   return !reg.test(item.whiteNumber);1
      // });
      // let arr1 = arr.filter(item => {
      //   return item.whiteNumber.length > 0 || item.whiteNumberName.length > 0;
      // });
      
      // 20220128 手机号限制不得低于5位
      let arr1 = state.batchObj.list.filter(item => {
        return item.whiteNumber.length < 5 || item.whiteNumberName.length <= 0;
      })
      if (arr1 && arr1.length > 0) {
        return true;
      }
      return false;
    });
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      Object.assign(state, {
        card: info.iccid, //获取卡号
        length: route.query.length || 0, //获取路由携带的白名单数量
        maxWhitelist: info.maxWhitelist //卡号所能添加的最大白名单数量
      });
      Object.assign(state.batchObj, { cardId: info.cardId }); //获取卡id
      state.deepList = JSON.parse(JSON.stringify(state.batchObj.list)); //深拷贝，初始化白名单对象的list
      //调用同步白名单接口
      ctx.asyncList();
    });
    return {
      ...toRefs(state),
      ...methods,
      tool,
      judgeMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.addList_box {
  height: 100vh;
  .content_box {
    height: calc(100vh - 106px);
    overflow-y: auto;
    .add_list {
      width: 690px;
      background: #ffffff;
      border-radius: 20px;
      margin: 30px auto;
      padding-bottom: 20px;
      .card_name,
      .card_num {
        background: #ffffff;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .desc_num {
          width: 36px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          color: black;
          border-radius: 50%;
          border: 1px solid black;
        }
        .delete {
          width: 32px;
          height: 32px;
        }
        .desc {
          font: {
            size: 30px;
            weight: 400;
          }
          color: #333333;
        }
        input {
          width: 430px;
          margin-right: 60px;
          color: #aaaaaa;
        }
        .index_input {
          width: 360px;
          margin-right: 60px;
          color: #aaaaaa;
        }
        /deep/ .van-field__right-icon {
          padding-right: 0;
          .inputNum {
            color: orangered;
          }
        }
      }
      .card_name {
        width: 650px;
      }
      .card_num {
        width: 580px;
        input {
          margin-right: 58px;
        }
      }
      .underline {
        width: 470px;
        height: 1px;
        background: #f2f2f2;
        margin: 0 180px;
      }
    }
    .add_button {
      width: 690px;
      height: 84px;
      color: black;
      border: none;
      background: #ffffff;
      border-radius: 20px;
      margin: 20px auto;
    }

    .submit {
      width: 690px;
      height: 84px;
      border: none;
      border-radius: 20px;
      margin: 30px auto;
      @include background_color("background_color1");
    }
    .explain {
      width: 670px;
      height: 218px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .explain_desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
        margin-bottom: 10px;
      }
      .explain_text {
        font: {
          size: 26px;
          weight: 400;
        }
        color: #777777;
      }
    }
  }
}
.van-cell {
  /deep/ .van-field__label {
    width: 80px;
    font-size: 30px;
    color: #333333;
  }
  /deep/ .van-field__value {
    .van-field__body {
      .van-field__control {
        font-size: 28px;
        color: #aaaaaa;
      }
    }
  }
}
</style>
